.input{
  border: none;
  width: 100%;
  height: 50px !important;
  background-color: #f2f4f7 !important;
  border-radius: 10px;
  padding-left: 15px;
  font-size: 16px !important;
  box-shadow: none !important;
  border: none !important;
  margin-bottom: 5px;
}

.input-table-error{
  border: 1px solid red !important;
}

.input:focus{
  border: none !important;
  box-shadow: none !important;
}

.input::placeholder{
  color: #555864 !important;
}

.input::-webkit-input-placeholder{
  color: #555864 !important;
}

#swal-input3{
  margin-top: 10px;
}

#swal-input2{
  margin-top: 10px;
}

.swal2-popup{
  border-radius: 25px !important;
}

.swal2-styled.swal2-cancel{
  width: 30%;
  height: 50px;
  border-radius: 25px !important;
  border: 2px solid #3E70E8 !important;
  background-color: #fff !important;
  color: #3E70E8 !important;
  font-weight: bold;
  font-size: 15px !important;
  box-shadow: none !important;
}

.swal2-styled.swal2-cancel:hover{
  background-color: #3E70E8 !important;
  color: #fff !important;
}

.swal2-styled.swal2-confirm{
  width: 30%;
  height: 50px;
  border-radius: 25px !important;
  border: none;
  background-color: #3E70E8 !important;
  color: #fff;
  font-weight: bold;
  font-size: 15px !important;
  box-shadow: none !important;
}

.swal2-styled.swal2-confirm:hover{
  background-color: #7297EF !important;
}

.swal2-progress-steps .swal2-progress-step-line{
  background: #3E70E8 !important;
}

.swal2-progress-steps .swal2-progress-step{
  background: #3E70E8 !important;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #3E70E8 !important;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progreççlLss-step {
  background: #CACDDF !important;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step-line{
  background: #CACDDF !important;
}

input:disabled::-webkit-input-placeholder {
  color: #979BB2 !important;
}

.swal2-select{
  width: 100%;
  background: #f2f4f7 !important;
  border-radius: 10px;
  color: #555864 !important;
  border: none;
  font-size: 16px;
  height: 50px;
}

.swal2-title{
  color: #3E70E8 !important;
  font-size: 24px !important;
}

.swal2-validation-message{
  background: transparent !important;
  color: #f27474 !important;
  font-size: 15px !important;
}

.swal2-popup{
  border-radius: 25px !important;
}

.swal2-actions{
  margin-top: 5px !important;
}

@media only screen and (max-width: 800px){
  .swal2-actions{
    flex-direction: column-reverse !important;
  }

  .swal2-styled.swal2-confirm{
    width: 80%;
  }

  .swal2-styled.swal2-cancel{
    width: 80%;
  }
}

.swal2-validation-message::before{
  display: none !important;
}

.swal2-content{
  padding: 0 !important;
}

.modal-content{
  border-radius: 25px !important;
}

.react-datepicker__month-text{
  font-weight: 100 !important;
  color: #555864 !important;
  text-transform: uppercase !important;
}

.react-datepicker__month--selected{
  background-color: #3e70e8 !important;
  color: #cacddf !important;
}

.react-datepicker__header{
  padding-bottom: 5px !important;
  color: #555864 !important;
}

.table td, .table th{
  vertical-align: middle !important;
  text-align: center;
}

.institutionSelect{
  background-color: #3E70E8 !important;
  color: #fff !important;
}

.institutionSelect option:checked{
  background-color: #3E70E8 !important;
  color: #fff !important;
}

@media only screen and (min-width: 800px){
  .instituitionSelectContainer{
    margin-right: 100px !important;
  }
}


.tooltip-inner {
  background-color: #3E70E8 !important;
  }
  .tooltip.bs-tooltip-right .arrow:before {
      border-right-color: #3E70E8 !important;
  }
  .tooltip.bs-tooltip-left .arrow:before {
      border-left-color: #3E70E8 !important;
  }
  .tooltip.bs-tooltip-bottom .arrow:before {
      border-bottom-color: #3E70E8 !important;
  }
  .tooltip.bs-tooltip-top .arrow:before {
      border-top-color: #3E70E8 !important;
  }
